<script>
export default {
  name: "CustomShowIcon",
  props: {
    filename: {
      type: String,
      default(){
        return ''
      }
    },
    customImage: {
      type: Boolean,
      default(){
        return false
      }
    },
    customImageResource: {
      type: String,
      default(){
        return ''
      }
    },
  },
  data(){
    return {
      // 图片
      imageSuffix: [
        'JPG','PNG','.xbm','.tif','pjp','.svgz',
        'jpg','jpeg','ico','tiff',
        '.gif','svg','.jfif',
        '.webp','.png','.bmp',
        'pjpeg','.avif',
      ],
      // 压缩文件
      compressFilesSuffix: [
        'RAR', 'ZIP',
      ],
      // 其他文件
      otherSuffix: [
        'CAD', 'DWG',
      ],
      //文档
      docSuffix: [
        'doc','PDF','Docx',
      ],
      // 表格
      tableSuffix: [
        '.xls', '.xlsx', '.xlsm', '.csv',
        '.csv.zip', '.numbers', '.xlsb', '.xlsbf',
        '.xltx', '.xltm', '.xlsr', '.xlsxdocument',
        '.xlsm', '.xlt', '.xlsbfile', '.xlw', '.xla',
      ],
      //
      showIcon1: require('@/assets/icon/image.png'),
      showIcon2: require('@/assets/icon/image13.png'),
      showIcon3: require('@/assets/icon/image9.png'),
      showIcon4: require('@/assets/icon/image4.png'),
      showIcon5: require('@/assets/icon/image12.png'),
      showIcon6: require('@/assets/icon/image3.png'),
      //
      showIcon7: require('@/assets/icon/DownloadSimple.png'),
      showIcon8: require('@/assets/icon/UploadSimple.png'),
      //
      showIcon10: require('@/assets/icon/icon.png'),
    }
  },
  computed: {
    showIconImg(){
      //
      if (this.customImage) {
        if (this.customImageResource === 'download') {
          return this.showIcon7;
        }
        if (this.customImageResource === 'upload') {
          return this.showIcon8;
        }
        if (this.customImageResource === 'menu') {
          return this.showIcon10;
        }
        return this.showIcon1;
      }

      //
      for (let imageSuffix of this.imageSuffix) {
        if (this.filename.toUpperCase().endsWith(imageSuffix.toUpperCase())) {
          return this.showIcon1;
        }
      }
      for (let imageSuffix of this.compressFilesSuffix) {
        if (this.filename.toUpperCase().endsWith(imageSuffix.toUpperCase())) {
          return this.showIcon2;
        }
      }
      for (let imageSuffix of this.otherSuffix) {
        if (this.filename.toUpperCase().endsWith(imageSuffix.toUpperCase())) {
          return this.showIcon3;
        }
      }
      for (let imageSuffix of this.docSuffix) {
        if (this.filename.toUpperCase().endsWith(imageSuffix.toUpperCase())) {
          if (imageSuffix.toUpperCase() === 'PDF') {
            return this.showIcon4;
          }
          return this.showIcon5;
        }
      }
      for (let imageSuffix of this.tableSuffix) {
        if (this.filename.toUpperCase().endsWith(imageSuffix.toUpperCase())) {
          return this.showIcon6;
        }
      }

      return this.showIcon3;
    }
  }
}
</script>

<template>
  <div class="custom-icon-container">
    <img :src="showIconImg" alt="">
  </div>
</template>

<style scoped lang="scss">
.custom-icon-container{
  position: relative;
  display: inline-block;
  height: 16px;
  width: 16px;
  img {
    position: absolute;
    top: 2px;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
</style>