import helper from "@/utils/helper";
import {BASE_URI} from "@/utils/config";
import {downloadFile} from "@/api/apis";
import store from "@/store";

export const viewImages = (imagesStr) => {
  const res = []
  const arr = (imagesStr || '').split(',')
  arr.forEach(iter => {
    iter && res.push(helper.getSourceUri(iter))
  })
  return res
}

export const designFileTypeVerifily = name => {
  const suffixs = [
    'CAD', 'DWG', 'RAR', 'ZIP','JPG','PNG'
  ]
  let res = false
  suffixs.forEach(iter => {
    if (name.toUpperCase().endsWith(iter.toUpperCase())) {
      res = true
    }
  })
  return res
}

export const gridFileTypeVerifily = name => {
  const suffixs = [
    'Docx', 'PDF', 'RAR',
    'ZIP', 'JPG', 'PNG', 'doc'
  ]
  let res = false
  suffixs.forEach(iter => {
    if (name.toUpperCase().endsWith(iter.toUpperCase())) {
      res = true
    }
  })
  return res
}

export const deliveryInventoryVerifily = name => {
  const suffixs = [
      // 图片
      '.xbm','.tif','pjp','.svgz',
      'jpg','jpeg','ico','tiff',
      '.gif','svg','.jfif',
      '.webp','.png','.bmp',
      'pjpeg','.avif',
      // 表格
      '.xls', '.xlsx', '.xlsm', '.csv',
      '.csv.zip', '.numbers', '.xlsb', '.xlsbf',
      '.xltx', '.xltm', '.xlsr', '.xlsxdocument',
      '.xlsm', '.xlt', '.xlsbfile', '.xlw', '.xla',
  ]
  let res = false
  suffixs.forEach(iter => {
    // console.log(name.toUpperCase().endsWith(iter.toUpperCase()))
    // console.log(name.toUpperCase());
    // console.log(iter.toUpperCase());
    if (name.toUpperCase().endsWith(iter.toUpperCase())) {
      res = true
    }
  })
  return res
}

export const isImage = name => {
  const suffixs = [
    '.xbm','.tif','pjp','.svgz',
    'jpg','jpeg','ico','tiff',
    '.gif','svg','.jfif',
    '.webp','.png','.bmp',
    'pjpeg','.avif'
  ]
  let res = false
  suffixs.forEach(iter => {
    if (name.toUpperCase().endsWith(iter.toUpperCase())) {
      res = true
    }
  })
  return res
}

export const save = function (blob, name) {
  const url = window.URL.createObjectURL(new Blob([blob]))
  const link = document.createElement('a')

  link.href = url
  link.setAttribute('download', name)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

/**
 * 保存文件
 * @param downLink
 * @param name
 */
export const exportPicture = function (downLink, name){
  // console.log(downLink, '-------link--');
  let link = downLink
  if (link.startsWith(BASE_URI)) {
    link = link.replace(BASE_URI, '')
  }
  downloadFile(link).then(res => {
    save(res, name)
  })
}


export const developerCheck = (state) => {
  const info = store.state.auth.user
  let flag
  if(state === "EXAMINE"){
    // 经销商 info.userType==="distributor"
    flag = info.userType === "distributor"
  } else {
    // 开发商 info.userType==="enterprise"
    flag = info.userType === "enterprise"
  }
  return flag
}

export const storeGetters = {
  getAreaCode: function (type='state'){
    if (type === 'state') {
      return store?.state?.auth?.user?.area_site?.area_code || store.getters["auth/getCode"]
    }
    return store.getters["auth/getCode"]
  },
  getSiteName: function (){
    return store?.state?.auth?.user?.area_site?.name || ''
  },
  getSiteTitle: function (){
    return store?.state?.auth?.user?.area_site?.title || ''
  },
  getRealname: function (){
    return store?.state?.auth?.user?.realname || ''
  },
  getLogo: function (){
    return store?.state?.auth?.user?.area_site?.logo || ''
  }
}
